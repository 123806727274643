import '../css/app.css';
import 'swiper/css';
import './dropdown.ts';
import { signUpNewsletter } from './newsletter';
import { timeline } from './timeline.ts';
import { switchLanguage } from './language.ts';
import { setupSlider } from './slider.ts';

export const main = async (): Promise<void> => {
  signUpNewsletter();
  switchLanguage();

  const sliders: NodeListOf<HTMLElement> =
    document.querySelectorAll('.js-slider');

  if (sliders) {
    setupSlider();
  }

  const tabs: NodeListOf<HTMLButtonElement> =
    document.querySelectorAll('[role="tab"]');
  const tabList: Element | null = document.querySelector('[role="tablist"]');

  if (tabs && tabList) {
    const tabs = await import('./tabs');
    tabs.tabsHandler();
  }

  const dialogButtons: NodeListOf<HTMLButtonElement> =
    document.querySelectorAll('.js-show-dialog');

  if (dialogButtons !== null && dialogButtons.length > 0) {
    const dialogs = await import('./dialog');
    dialogs.dialogHandler();
  }

  const matches: NodeListOf<HTMLElement> =
    document.querySelectorAll('.js-live');

  if (matches && matches.length > 0) {
    const liveMatches = await import('./liveMatches');
    liveMatches.liveMatches();
  }

  const liteYtEmbed: NodeListOf<HTMLElement> =
    document.querySelectorAll('lite-youtube');

  if (liteYtEmbed !== null && liteYtEmbed.length > 0) {
    const { videoHandler } = await import('./video');
    videoHandler();
  }

  const timelineElement: HTMLElement | null =
    document.querySelector('.js-timeline');

  if (timelineElement) {
    await timeline();
  }

  const countdown = document.querySelector('.js-countdown') as HTMLDivElement;
  if (countdown) {
    const { handleCountdown } = await import('./countdown');
    handleCountdown();
  }
  const cta: HTMLElement | null =
    document.querySelector<HTMLElement>('.js-global-cta');
  if (cta) {
    const { handleGlobalCta } = await import('./globalCta');
    handleGlobalCta();
  }
};

void main();

if (import.meta.hot) {
  import.meta.hot.accept();
}
