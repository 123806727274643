import { Elements } from './@types/dropdown';

const dropdownWrapper: NodeListOf<HTMLElement> =
  document.querySelectorAll('.js-dropdown');

const dropdownHandler = (parent: HTMLElement): void => {
  const elements: Elements = {
    button: parent.querySelector('[role="combobox"]'),
    dropdown: parent.querySelector('[role="listbox"]'),
    options: parent.querySelectorAll('[role="option"]'),
    announcement: document.getElementById('announcement'),
  };

  let isDropdownOpen: boolean = false;
  let currentOptionIndex: number = 0;
  let lastTypedChar: string = '';
  let lastMatchingIndex: number = 0;

  const toggleDropdown = (): void => {
    elements.dropdown?.classList.toggle('active');
    isDropdownOpen = !isDropdownOpen;
    elements.button?.setAttribute('aria-expanded', isDropdownOpen.toString());

    if (isDropdownOpen) {
      focusCurrentOption();
    } else {
      elements.button?.focus();
    }
  };

  const handleKeyPress = (event: KeyboardEvent): void => {
    const { key } = event;
    const openKeys: string[] = ['ArrowDown', 'ArrowUp', 'Enter', ' '];

    if (!openKeys.includes(key)) {
      return;
    }
    event.preventDefault();

    if (!isDropdownOpen && openKeys.includes(key)) {
      toggleDropdown();
    } else if (isDropdownOpen) {
      switch (key) {
        case 'Escape':
          toggleDropdown();
          break;
        case 'ArrowDown':
          moveFocusDown();
          break;
        case 'ArrowUp':
          moveFocusUp();
          break;
        case 'Enter':
        case ' ':
          selectCurrentOption();
          break;
        default:
          // Handle alphanumeric key presses for mini-search
          handleAlphanumericKeyPress(key);
          break;
      }
    }
  };

  const handleDocumentInteraction = (event: MouseEvent): void => {
    // do something when having multiple dropdowns
    const target = event.target as HTMLElement;
    const isClickInsideButton: boolean | undefined =
      elements.button?.contains(target);
    const isClickInsideDropdown: boolean | undefined =
      elements.dropdown?.contains(target);

    if (isClickInsideButton || (!isClickInsideDropdown && isDropdownOpen)) {
      toggleDropdown();
    }

    // Check if the click is on an option
    const clickedOption: HTMLLIElement | null =
      target.closest('[role="option"]');
    if (clickedOption) {
      selectOptionByElement(clickedOption);
    }
  };

  const moveFocusDown = (): void => {
    if (currentOptionIndex < elements.options.length - 1) {
      currentOptionIndex++;
    } else {
      currentOptionIndex = 0;
    }
    focusCurrentOption();
  };

  const moveFocusUp = (): void => {
    if (currentOptionIndex > 0) {
      currentOptionIndex--;
    } else {
      currentOptionIndex = elements.options.length - 1;
    }
    focusCurrentOption();
  };

  const focusCurrentOption = (): void => {
    const currentOption: HTMLLIElement = elements.options[currentOptionIndex];
    const optionLabel: string | null = currentOption.textContent;

    currentOption.classList.add('current');
    currentOption.focus();

    // Scroll the current option into view
    currentOption.scrollIntoView({
      block: 'nearest',
    });

    elements.options.forEach((option: HTMLLIElement) => {
      if (option !== currentOption) {
        option.classList.remove('current');
      }
    });
    announceOption(`You're currently focused on ${optionLabel}`); // Announce the selected option within a delayed period
  };

  const selectCurrentOption = (): void => {
    const selectedOption: HTMLLIElement = elements.options[currentOptionIndex];
    selectOptionByElement(selectedOption, true);
  };

  const selectOptionByElement = (
    optionElement: HTMLLIElement,
    performClick: boolean = false,
  ): void => {
    const optionValue: string | null = optionElement.textContent;
    const buttonTextElements: HTMLSpanElement | null | undefined =
      elements.button?.querySelector('span');

    if (!buttonTextElements) {
      return;
    }

    buttonTextElements.textContent = optionValue;
    elements.options.forEach((option: HTMLLIElement) => {
      option.classList.remove('active');
      option.setAttribute('aria-selected', 'false');
    });

    optionElement.classList.add('active');
    optionElement.setAttribute('aria-selected', 'true');
    if (performClick) {
      optionElement.click();
    }
    toggleDropdown();
    announceOption(optionValue ?? ''); // Announce the selected option
  };

  const handleAlphanumericKeyPress = (key: string): void => {
    const typedChar = key.toLowerCase();

    if (lastTypedChar !== typedChar) {
      lastMatchingIndex = 0;
    }

    const matchingOptions = Array.from(elements.options).filter(
      (option: HTMLLIElement) =>
        option.textContent?.toLowerCase().startsWith(typedChar),
    );

    if (matchingOptions.length) {
      if (lastMatchingIndex === matchingOptions.length) {
        lastMatchingIndex = 0;
      }
      const value: HTMLLIElement = matchingOptions[lastMatchingIndex];
      currentOptionIndex = Array.from(elements.options).indexOf(value);
      focusCurrentOption();
      lastMatchingIndex += 1;
    }
    lastTypedChar = typedChar;
  };

  const announceOption = (text: string): void => {
    if (elements.announcement === null) {
      return;
    }
    elements.announcement.textContent = text;
    elements.announcement.setAttribute('aria-live', 'assertive');
    setTimeout(() => {
      if (elements.announcement === null) {
        return;
      }
      elements.announcement.textContent = '';
      elements.announcement.setAttribute('aria-live', 'off');
    }, 1500);
  };

  elements.button?.addEventListener('keydown', handleKeyPress);
  parent.addEventListener('click', handleDocumentInteraction);
};

if (dropdownWrapper && dropdownWrapper.length > 0) {
  dropdownWrapper.forEach((parent: HTMLElement) => {
    dropdownHandler(parent);
  });
}
