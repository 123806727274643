export const signUpNewsletter = (): void => {
  const form: HTMLFormElement | null = document.querySelector('.js-newsletter');
  const success: HTMLElement | null =
    document.querySelector('.js-form-success');

  if (!form) {
    return;
  }

  form.addEventListener('submit', async (ev: SubmitEvent) => {
    ev.preventDefault();
    const formData: FormData = new FormData(form);
    const object: { [key: string]: FormDataEntryValue } = {};
    formData.forEach(
      (value: FormDataEntryValue, key: string) => (object[key] = value),
    );
    try {
      const request: Response = await fetch('/newsletter/subscribe', {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
        },
        body: JSON.stringify(object),
      });

      const response = await request.json();

      if (response.status == 201) {
        form.classList.add('hidden');
        success?.classList.remove('hidden');
      } else {
        throw new Error(response.message ?? 'Something went wrong');
      }
    } catch (e) {
      const errorElements: HTMLElement | null =
        document.querySelector('.js-form-error');
      if (e instanceof Error) {
        console.error(e.message ?? 'Something went wrong');
        if (errorElements && e.message) {
          errorElements.innerHTML = e.message;
        }
      }
      errorElements?.classList.remove('hidden');
    }
  });
};
