/**
 * This file defines an asynchronous function `timeline` that handles the loading and updating
 * of events for a timeline element on a webpage. It fetches event data from an API, updates
 * the DOM with the events, and periodically refreshes the events if the timeline is live.
 */

export const timeline: () => Promise<void> = async () => {
  const timeline: HTMLElement | null = document.querySelector('.js-timeline');

  if (!timeline) {
    return;
  }

  const loading: HTMLElement | null = timeline.querySelector('.js-loading');
  const expired: HTMLElement | null = timeline.querySelector('.js-expired');
  const title: HTMLElement | null = timeline.querySelector('h2');
  const eventWrapper: HTMLElement | null = timeline.querySelector(
    '.js-timeline-event-wrapper',
  );

  if (!eventWrapper || !loading || !title || !expired) {
    return;
  }

  const loadEvents: (matchId: string) => Promise<[]> = async (
    matchId: string,
  ) => {
    loading.classList.remove('hidden');
    try {
      const request: Response = await fetch(
        `/hockey-api/matches/${matchId}/actions`,
        {
          method: 'POST',
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            'X-CSRF-Token': window._token,
            'X-Requested-With': 'XMLHttpRequest',
          },
        },
      );

      if (!request.ok) {
        console.error('Network response was not ok');
        return;
      }

      const response = await request.json();

      if (!('data' in response)) {
        console.error('No data received');
        return;
      }

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const matchId: string | undefined = timeline.dataset.matchId;
  const isLive: string | undefined = timeline.dataset.isLive;

  const handleEvents: () => Promise<void> = async () => {
    eventWrapper.innerHTML = '';
    const events = await loadEvents(matchId ?? '');

    expired.classList.add('hidden');
    loading.classList.add('hidden');
    if (events.length > 0) {
      if (title?.classList.contains('hidden')) {
        title?.classList.remove('hidden');
      }
      events.forEach((element: string) => {
        eventWrapper.innerHTML += element;
      });
    } else {
      if (isLive === 'true') {
        expired.classList.remove('hidden');
      } else {
        timeline.classList.add('hidden');
      }
    }
  };

  await handleEvents(); // Run  once at least
  if (isLive === 'true') {
    setInterval(handleEvents, 30000); // 30seconds
  }
};
