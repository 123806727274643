const announcement = document.getElementById('announcement');
export const switchLanguage = () => {
  const languageSelects: NodeListOf<HTMLSelectElement> =
    document.querySelectorAll('.js-language-select');

  if (!languageSelects) {
    return;
  }

  languageSelects.forEach((languageSelect) => {
    languageSelect.addEventListener('change', (e) => {
      const target = e.target as HTMLSelectElement;
      if (announcement) {
        announceOption(`Switching language`);
      }
      window.location.href = target.value;
    });
  });
};

const announceOption = (text: string): void => {
  if (announcement === null) {
    return;
  }
  announcement.textContent = text;
  announcement.setAttribute('aria-live', 'assertive');
  setTimeout(() => {
    if (announcement === null) {
      return;
    }
    announcement.textContent = '';
    announcement.setAttribute('aria-live', 'off');
  }, 1500);
};
